import { KeycloakService } from 'keycloak-angular';

import { inject, Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { SnackbarService } from '../snackbar-service/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly _keycloakService = inject(KeycloakService);
  private readonly _snackBarService = inject(SnackbarService);

  redirectToLoginPage(): Promise<void> {
    return this._keycloakService.login();
  }

  isLoggedIn(): boolean {
    return this._keycloakService.isLoggedIn();
  }

  async getToken(): Promise<string> {
    try {
      return await this._keycloakService.getToken();
    } catch (error) {
      console.error('Error getting token', error);
      throw error;
    }
  }

  logout(): void {
    this._keycloakService.logout(environment.keycloak.postLogoutRedirectUri);
  }

  isTokenExpired(): boolean {
    return this._keycloakService.isTokenExpired();
  }

  showAuthenticationMessage(): void {
    this._snackBarService.openFailureSnackBar('Você não está autenticado', 'OK');
  }
}